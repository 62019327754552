var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { height: "300px", width: "400px" },
          on: { "click:outside": _vm.redirectHome },
          model: {
            value: this.display,
            callback: function ($$v) {
              _vm.$set(this, "display", $$v)
            },
            expression: "this.display",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-center text-h4 mt-6" },
                [
                  _c("v-icon", { attrs: { color: "error", size: "100" } }, [
                    _vm._v(" mdi-alert "),
                  ]),
                  _vm._v(" Unauthorized User "),
                ],
                1
              ),
              _c(
                "v-card-subtitle",
                { staticClass: "d-flex justify-center text-center" },
                [
                  _vm._v(
                    " Sorry but your account is not authorized for TaxPOD VISION "
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectHome },
                    },
                    [_vm._v(" Go to login page ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }